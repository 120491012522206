import React from 'react';
import { motion } from 'framer-motion';
import { AlertCircle } from 'lucide-react';
import { Link } from "react-router-dom";


const ModernMenu = ({ items = [] }) => {
  if (items.length > 0) {
    return <div>Menu items would be displayed here</div>;
  }

  return (
    <div className="min-h-96 flex items-center justify-center">
      <motion.div 
        className="bg-white rounded-xl shadow-lg p-8 max-w-md w-full text-center"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <motion.div
          animate={{ rotate: [0, 10, -10, 0] }}
          transition={{ repeat: Infinity, duration: 2, ease: "easeInOut" }}
        >
          <AlertCircle className="w-16 h-16 text-[#8B0000] mx-auto mb-4" />
        </motion.div>
        <motion.h2 
          className="text-2xl font-bold text-gray-800 mb-2"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.5 }}
        >
          Our Menu is Taking a Break
        </motion.h2>
        <motion.p 
          className="text-gray-600 mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
        >
          Our chefs are crafting something special. Check back soon for a feast of flavors!
        </motion.p>
        <Link to='/category'>
        <motion.button 
          className="bg-[#8B0000] text-white px-6 py-2 rounded-full font-semibold hover:bg-[#e70000] transition-colors duration-300"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          >
          Back to Category
        </motion.button>
          </Link>
      </motion.div>
    </div>
  );
};

export default ModernMenu;