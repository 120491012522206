import React, { useState, useEffect, useCallback } from 'react';
import api from '../../api/axios';
import { X, Plus, Edit2, Trash2, Upload, Eye, EyeOff } from 'lucide-react';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          <X size={24} />
        </button>
        {children}
      </div>
    </div>
  );
};

const Toggle = ({ checked, onChange, disabled }) => (
  <button
    type="button"
    disabled={disabled}
    className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
      checked ? 'bg-blue-600' : 'bg-gray-200'
    } ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
    onClick={onChange}
  >
    <span
      className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
        checked ? 'translate-x-6' : 'translate-x-1'
      }`}
    />
  </button>
);

const ItemForm = ({ onSubmit, initialData, onClose }) => {
  const [formData, setFormData] = useState({
    name: initialData?.name || '',
    nameKu: initialData?.nameKu || '',
    nameAr: initialData?.nameAr || '',
    isActive: initialData?.isActive ?? true
  });
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(initialData?.image ? `https://cc-cafe-rest.com//${initialData.image}` : null);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setError(''); // Clear error when user makes changes
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        if (file.size > 5 * 1024 * 1024) { // 5MB limit
          setError('Image size should be less than 5MB');
          e.target.value = null;
          return;
        }
        setImage(file);
        const reader = new FileReader();
        reader.onloadend = () => setImagePreview(reader.result);
        reader.readAsDataURL(file);
        setError('');
      } else {
        setError('Please select a valid image file');
        e.target.value = null;
      }
    }
  };

  const validateForm = () => {
    const errors = [];
    if (!formData.name.trim()) errors.push('English title is required');
    if (!formData.nameKu.trim()) errors.push('Kurdish title is required');
    if (!formData.nameAr.trim()) errors.push('Arabic title is required');
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (errors.length > 0) {
      setError(errors.join(', '));
      return;
    }

    try {
      const submitData = new FormData();
      Object.keys(formData).forEach(key => {
        submitData.append(key, formData[key]);
      });

      if (image) {
        submitData.append('image', image);
      }

      await onSubmit(submitData, initialData?.id);
      onClose();
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred while saving');
    } finally {
      setImage(null);
      setImagePreview(null);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <h2 className="text-2xl font-bold mb-4">
        {initialData ? 'Edit Item' : 'Add New Item'}
      </h2>
      
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
          <span className="block sm:inline">{error}</span>
        </div>
      )}

      <div className="space-y-4">
        {/* English Title */}
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
            English Title
          </label>
          <input
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter English title"
          />
        </div>

        {/* Kurdish Title */}
        <div>
          <label htmlFor="nameKu" className="block text-sm font-medium text-gray-700 mb-1">
            Kurdish Title
          </label>
          <input
            id="nameKu"
            name="nameKu"
            value={formData.nameKu}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter Kurdish title"
            dir="rtl"
          />
        </div>

        {/* Arabic Title */}
        <div>
          <label htmlFor="nameAr" className="block text-sm font-medium text-gray-700 mb-1">
            Arabic Title
          </label>
          <input
            id="nameAr"
            name="nameAr"
            value={formData.nameAr}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter Arabic title"
            dir="rtl"
          />
        </div>

        {/* Image Upload */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Image</label>
          <div className="mt-1 flex items-center">
            <label htmlFor="image-upload" className="cursor-pointer bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              <Upload className="h-5 w-5 inline-block mr-2" />
              Upload Image
            </label>
            <input
              id="image-upload"
              type="file"
              className="sr-only"
              accept="image/*"
              onChange={handleImageChange}
            />
          </div>
          {imagePreview && (
            <div className="mt-2">
              <img src={imagePreview} alt="Preview" className="h-32 w-32 object-cover rounded-md" />
            </div>
          )}
        </div>

        {/* Active Status */}
        <div className="flex items-center justify-between">
          <label htmlFor="isActive" className="text-sm font-medium text-gray-700">
            Active Status
          </label>
          <Toggle
            checked={formData.isActive}
            onChange={() => setFormData(prev => ({ ...prev, isActive: !prev.isActive }))}
          />
        </div>
      </div>

      <div className="flex justify-end space-x-2 pt-4">
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
        >
          {initialData ? 'Update' : 'Add'} Item
        </button>
      </div>
    </form>
  );
};

const MultilingualCRUD = () => {
  const [items, setItems] = useState([]);
  console.log(items);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [togglingItems, setTogglingItems] = useState(new Set()); // Track items being toggled

  const fetchItems = useCallback(async () => {
    setIsLoading(true);
    setError('');
    try {
      const response = await api.get('/allcategories');
      setItems(response.data.data.data);
    } catch (err) {
      setError('Failed to fetch items. Please try again later.');
      console.error('Error fetching items:', err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const handleStatusToggle = async (item) => {
    setTogglingItems(prev => new Set(prev).add(item.id));
    try {
      const formData = new FormData();
      // Only send the necessary fields for status update
      formData.append('isActive', !item.isActive);
      formData.append('name', item.name);
      formData.append('nameKu', item.nameKu);
      formData.append('nameAr', item.nameAr);
      
      await api.put(`/categories/${item.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      // Update local state after successful API call
      setItems(prevItems =>
        prevItems.map(prevItem =>
          prevItem.id === item.id
            ? { ...prevItem, isActive: !prevItem.isActive }
            : prevItem
        )
      );
    } catch (err) {
      setError('Failed to update status. Please try again.');
      console.error('Error updating status:', err);
    } finally {
      setTogglingItems(prev => {
        const newSet = new Set(prev);
        newSet.delete(item.id);
        return newSet;
      });
    }
  };

  const handleSubmit = async (formData, itemId) => {
    setError('');
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      if (itemId) {
        await api.put(`/categories/${itemId}`, formData, config);
      } else {
        await api.post('/categories', formData, config);
      }

      await fetchItems();
      setIsModalOpen(false);
      setEditingItem(null);
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred while saving the item');
    } finally {
      setEditingItem(null);
    }
  };

  const handleDelete = async () => {
    if (!itemToDelete) return;
    
    try {
      await api.delete(`/categories/${itemToDelete.id}`);
      await fetchItems();
      setIsDeleteConfirmOpen(false);
      setItemToDelete(null);
    } catch (err) {
      setError('Failed to delete item. Please try again.');
    } finally {
      setItemToDelete(null);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 max-w-6xl mx-auto my-10">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold text-gray-800">Categories Management</h2>
        <button
          onClick={() => setIsModalOpen(true)}
          className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg flex items-center gap-2"
        >
          <Plus size={20} /> Add New Item
        </button>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
          <span className="block sm:inline">{error}</span>
        </div>
      )}

      {items.length === 0 ? (
        <p className="text-center text-gray-500 my-4">No items found. Add a new item to get started.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Image</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">English Title</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Kurdish Title</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Arabic Title</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {items.map(item => (
                <tr key={item.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <img
                      src={`https://cc-cafe-rest.com//${item.image}`}
                      alt={item.name}
                      className="w-12 h-12 rounded-lg object-cover"
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{item.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-right">{item.nameKu}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-right">{item.nameAr}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center gap-2">
                    <Toggle
                      checked={item.isActive}
                      onChange={() => handleStatusToggle(item)}
                      disabled={togglingItems.has(item.id)}
                    />
                      {item.isActive ? (
                        <Eye className="h-4 w-4 text-green-500" />
                      ) : (
                        <EyeOff className="h-4 w-4 text-gray-500" />
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => {
                          setEditingItem(item);
                          setIsModalOpen(true);
                        }}
                        className="p-2 hover:bg-blue-100 rounded-md text-blue-600"
                      >
                        <Edit2 className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => {
                          setItemToDelete(item);
                          setIsDeleteConfirmOpen(true);
                        }}
                        className="p-2 hover:bg-red-100 rounded-md text-red-600"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}


      <Modal isOpen={isModalOpen} onClose={() => {
        setIsModalOpen(false);
        setEditingItem(null);
      }}>
        <ItemForm
          onSubmit={handleSubmit}
          initialData={editingItem}
          onClose={() => {
            setIsModalOpen(false);
            setEditingItem(null);
          }}
        />
      </Modal>

      <Modal isOpen={isDeleteConfirmOpen} onClose={() => setIsDeleteConfirmOpen(false)}>
        <div className="text-center">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Confirm Deletion</h3>
          <p className="text-sm text-gray-500 mb-4">
            Are you sure you want to delete the category "{itemToDelete?.name}"? This action cannot be undone.
          </p>
          <div className="flex justify-center space-x-4">
            <button
              onClick={() => setIsDeleteConfirmOpen(false)}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition duration-300"
            >
              Cancel
            </button>
            <button
              onClick={handleDelete}
              className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition duration-300"
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MultilingualCRUD;
