import React, { useState } from "react";
import useAuth from "../hooks/useAuth";
import { api,setAuthToken  } from "../api/axios";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [username, setUser] = useState('');
  const [password, setPwd] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isForgetPassword, setIsForgetPassword] = useState(false);

  const { setAuth } = useAuth();  
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      const response = await api.post('/auth/login', { username, password });
      
      // Assuming the API returns a token in the response
      const { token } = response.data;

      // Set the token for future requests
      setAuthToken(token);
      

      setAuth({ isAuthenticated: true, username, token });
      navigate("/dashboard", { replace: true });
    } catch (err) {
      setError('Login failed. Please check your credentials.');
      setPwd('');
      setUser('');
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {isForgetPassword ? 'Reset your password' : 'Sign in to your account'}
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                Username
              </label>
              <div className="mt-1">
                <input
                  id="username"
                  name="username"
                  type="text"
                  autoComplete="username"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  value={username}
                  onChange={(e) => setUser(e.target.value)}
                />
              </div>
            </div>

            {!isForgetPassword && (
              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={password}
                    onChange={(e) => setPwd(e.target.value)}
                  />
                </div>
              </div>
            )}

            {error && (
              <div className="text-red-600 text-sm">{error}</div>
            )}

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                disabled={isLoading}
              >
                {isLoading ? 'Processing...' : isForgetPassword ? 'Reset Password' : 'Sign in'}
              </button>
            </div>
          </form>

          <div className="mt-6">
            <button
              onClick={() => setIsForgetPassword(!isForgetPassword)}
              className="w-full text-center text-sm text-indigo-600 hover:text-indigo-500"
            >
              {isForgetPassword ? 'Back to login' : 'Forgot your password?'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

