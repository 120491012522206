import React, { createContext, useState, useEffect, useCallback } from "react";
import { api, logout as apiLogout } from "../api/api";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    const storedUser = localStorage.getItem('user');
    
    return storedUser 
      ? { isAuthenticated: true, user: JSON.parse(storedUser) }
      : { isAuthenticated: false, user: null };
  });
  
  const [loading, setLoading] = useState(true);

  const checkAuth = useCallback(async () => {
    if (!auth.isAuthenticated) {
      setLoading(false);
      return;
    }

    try {
      const response = await api.get('/auth/status');
      setAuth({
        isAuthenticated: true,
        user: response.data,
      });
      localStorage.setItem('user', JSON.stringify(response.data));
    } catch (error) {
      console.error('Auth check failed:', error);
      setAuth({
        isAuthenticated: false,
        user: null,
      });
      localStorage.removeItem('user');
    } finally {
      setLoading(false);
    }
  }, [auth.isAuthenticated]);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  const login = useCallback(async (credentials) => {
    try {
      const response = await api.post('/auth/login', credentials);
      const userData = response.data;
      setAuth({
        isAuthenticated: true,
        user: userData,
      });
      localStorage.setItem('user', JSON.stringify(userData));
      return true; // Indicate successful login
    } catch (error) {
      console.error('Login failed:', error);
      return false; // Indicate failed login
    }
  }, []);

  const logout = useCallback(async () => {
    try {
      await apiLogout();
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      setAuth({
        isAuthenticated: false,
        user: null,
      });
      localStorage.removeItem('user');
    }
  }, []);

  const authContextValue = {
    auth,
    loading,
    setAuth,
    login,
    logout,
    checkAuth,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;