import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import cc_logo from "../Assets/img/cc.png";
import kuIcon from "../Assets/img/Flag_of_Kurdistan.svg";
import arIcon from "../Assets/img/flag-400.png";
import enIcon from "../Assets/img/united-states-flag-icon.svg";

const LanguageSelector = ({ t, i18n }) => {
  const navigate = useNavigate();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    
    // Store the selected language in localStorage for persistence
    localStorage.setItem('Language', lng);
    
    // Navigate to the category page with language parameter
    navigate(`/category`);
  };

  // Effect to check for previously selected language
  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  // Translations for welcome text
  const welcomeText = {
    en: {
      welcome: "Welcome to",
      restaurant: "The CC Cafe & Restaurant",
      explore: "Explore Our",
      menu: "Menu",
      chooseLang: "Please Choose a Language"
    },
    ar: {
      welcome: "مرحباً بكم في",
      restaurant: "مطعم ومقهى سي سي",
      explore: "استكشف",
      menu: "قائمتنا",
      chooseLang: "الرجاء اختيار اللغة"
    },
    ku: {
      welcome: "بەخێربێن بۆ",
      restaurant: "چێشتخانە و کافێی سی سی",
      explore: "سەیری",
      menu: "مێنیو",
      chooseLang: "تکایە زمانێک هەڵبژێرە"
    }
  };

  const getCurrentLanguageText = () => {
    const currentLang = i18n.language || 'en';
    return welcomeText[currentLang] || welcomeText.en;
  };

  const text = getCurrentLanguageText();

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-gray-900 to-gray-800">
      <div className="flex-1 flex flex-col justify-center items-center px-4 sm:px-6 lg:px-8">
        <img 
          src={cc_logo} 
          alt="CC Cafe & Restaurant" 
          className="w-52 h-52"
        />
        <h1 className="text-4xl sm:text-5xl font-extrabold text-white mb-2">
          {text.welcome}
        </h1>
        <h2 className="text-2xl sm:text-3xl font-bold text-[#8B0000] mb-8">
          {text.restaurant}
        </h2>
        <div className=" text-white text-center mb-4 ">
          <p className="font-semibold lg:-mb-4 text-left">{text.explore}</p>
          <h3 className="text-7xl lg:text-9xl font-bold text-[#f8f8f8]">
            {text.menu}
          </h3>
        </div>
        <h4 className="lg:text-2xl text-xl mb-2 font-bold text-white lg:mb-8  md:mt-4 md:text-2xl lg:mt-8">
          {text.chooseLang}
        </h4>
      </div>
      
      <div className="bg-[#8B0000] py-12">
        <div className="max-w-md mx-auto grid px-4 mb-12 gap-4">
          {[
            { lang: "en", text: "English", icon: enIcon, dir: "ltr" },
            { lang: "ar", text: "عربی", icon: arIcon, dir: "rtl" },
            { lang: "ku", text: "کوردی", icon: kuIcon, dir: "rtl" }
          ].map((item) => (
            <button
              key={item.lang}
              onClick={() => changeLanguage(item.lang)}
              className="flex items-center justify-between w-full px-6 py-3 text-lg font-medium text-white transition-all duration-200 bg-white bg-opacity-10 rounded-lg hover:bg-opacity-20 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#8B0000] focus:ring-white"
              style={{ direction: item.dir }}
            >
              <span>{item.text}</span>
              <img 
                src={item.icon} 
                alt={item.text} 
                className="w-8 h-8 object-contain" 
              />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LanguageSelector;