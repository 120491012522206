// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './locales/en/translation.json';
import arTranslations from './locales/ar/translation.json';
import kuTranslations from './locales/ku/translation.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslations },
      ar: { translation: arTranslations },
      ku: { translation: kuTranslations },
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    lng: localStorage.getItem('lng') || 'en',
  });

export default i18n;
