import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './i18n';
import './index.css';
import AuthProvider from './context/AuthProvider';
import LanguageSelector from './pages/LanguageSelector';
import CategorySelector from './pages/CategorySelector';
import MenuPage from "./pages/MenuPage";
import Login from './pages/login'; 
import Dashboard from './pages/Dashboard';
import RequireAuth from './components/RequireAuth';

function App() {
  const { t, i18n } = useTranslation();

  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<LanguageSelector t={t} i18n={i18n} />} />
          <Route path="/category" element={<CategorySelector t={t} i18n={i18n} />} />
          <Route path="/menu/:categoryId" element={<MenuPage t={t} i18n={i18n}/>} />
          <Route path="/login" element={<Login />} />

          {/* Protected Routes */}
          <Route element={<RequireAuth />}>
            <Route path="/dashboard" element={<Dashboard />} />
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;