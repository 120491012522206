import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { useParams, useLocation, Link } from 'react-router-dom';
import { Loader2, ChevronLeft, Search } from 'lucide-react';
import EmptyMenu from '../components/emptyMenu';

export default function QRFriendlyRestaurantMenu() {
  const { categoryId } = useParams();
  const location = useLocation();
  const categoryName = location.state?.categoryName || 'Our Menu';
  console.log('====================================');
  console.log('category:', location.state);
  console.log('====================================');
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const selectedLanguage = localStorage.getItem('Language') || 'en';
  const isRTL = selectedLanguage === 'ar' || selectedLanguage === 'ku';

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await axios.get(`https://cc-cafe-rest.com/api/menus/${categoryId}`,
          {
            params: { lang: selectedLanguage },
            headers: {
              'Accept-Language': selectedLanguage
            }
          }
        );
        setMenuItems(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setError(error);
        setLoading(false);
      }
    };
    fetchMenuItems();
  }, [categoryId, selectedLanguage]);

  const filteredItems = menuItems.filter(item => {
    const searchLower = searchTerm.toLowerCase();
    const name = item?.name || item?.nameKu || item?.nameAr || '';
    const description = item?.description || '';
    
    return name.toLowerCase().includes(searchLower) ||
           description.toLowerCase().includes(searchLower);
  });

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <Loader2 className="w-12 h-12 text-[#8B0000] animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="text-center bg-white p-8 rounded-xl shadow-lg">
          <h2 className="text-2xl font-bold text-gray-800 mb-2">Oops! Something went wrong.</h2>
          <p className="text-gray-600">{error.message}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100" dir={isRTL ? 'rtl' : 'ltr'}>
      <div className="bg-[#8B0000] text-white sticky top-0 z-10">
        <div className="container mx-auto px-4 py-4">
          <div>
            <Link to='/category' className="flex items-center mb-4">
              <ChevronLeft className="w-6 h-6 mr-2" />
              <h1 className="text-2xl font-bold">{categoryName}</h1>
            </Link>
          </div>
          <div className="relative">
            <input
              type="text"
              placeholder="Search menu items..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full py-2 px-4 pr-10 rounded-full bg-white bg-opacity-20 text-white placeholder-gray-200 focus:outline-none focus:ring-2 focus:ring-white"
            />
            <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 text-white w-5 h-5" />
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-8">
        {menuItems.length === 0 ? (
          <EmptyMenu />
        ) : (
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="grid gap-6"
            >
              {filteredItems.map((item) => (
                <motion.div
                  key={item.id}
                  layout
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  className="bg-white rounded-lg shadow-md overflow-hidden flex"
                >
                  <img
                    src={`https://cc-cafe-rest.com/${item.image}`}
                    alt={item.name}
                    className="w-1/3 h-32 object-cover"
                  />
                  <div className="p-4 flex-1 flex flex-col justify-between">
                    <div>
                      <h2 className="text-xl font-semibold text-gray-800 mb-1">
                        {item.name || item.nameKu || item.nameAr || ''}
                      </h2>
                    </div>
                    <div className="mt-2 text-[#8B0000] font-bold">
                      {(item.price || 0)} IQD
                    </div>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          </AnimatePresence>
        )}
      </div>
    </div>
  );
}