import React, { useState, useEffect } from "react";
import api from "../../api/axios";
import { X, Plus, Edit2, Trash2, Upload, Eye, EyeOff } from 'lucide-react';
import * as XLSX from 'xlsx';


const Toggle = ({ checked, onChange, disabled }) => (
  <button
    type="button"
    disabled={disabled}
    className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
      checked ? 'bg-blue-600' : 'bg-gray-200'
    } ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
    onClick={onChange}
  >
    <span
      className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
        checked ? 'translate-x-6' : 'translate-x-1'
      }`}
    />
  </button>
);

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <X size={24} />
        </button>
        {children}
      </div>
    </div>
  );
};

const MenuItemForm = ({ onSubmit, initialData, onClose }) => {
  const [name, setName] = useState(initialData?.name || "");
  const [nameAr, setNameAr] = useState(initialData?.nameAr || "");
  const [nameKu, setNameKu] = useState(initialData?.nameKu || "");
  const [isActive, setIsActive] = useState(initialData?.isActive ?? true);
  const [price, setPrice] = useState(initialData?.price || "");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(initialData?.image ? `https://cc-cafe-rest.com//${initialData.image}` : null);
  const [error, setError] = useState("");
  const [categoryId, setCategoryId] = useState(initialData?.category?.id || "");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Fetch categories from the API
    const fetchCategories = async () => {
      try {
        const response = await api.get("/categories");
        setCategories(response.data.data);
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
    };
    fetchCategories();

    if (initialData) {
      setName(initialData.name);
      setNameAr(initialData.nameAr);
      setNameKu(initialData.nameKu);
      setIsActive(initialData.isActive);
      setPrice(initialData.price);
      setImagePreview(initialData.image ? `https://cc-cafe-rest.com//${initialData.image}` : null);
      setCategoryId(initialData.category?.id || "");
    }
  }, [initialData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");

    if (!name.trim() || !price || !categoryId || !nameAr.trim() || !nameKu.trim()) {
      setError("All fields are required");
      return;
    }

    const formData = new FormData();
    formData.append("name", name.trim());
    formData.append("nameAr", nameAr.trim());
    formData.append("nameKu", nameKu.trim());
    formData.append("isActive", isActive);
    formData.append("price", price);
    formData.append("categoryId", categoryId); // Send CategoryId

    

    if (image) {
      formData.append("image", image);
    }

    onSubmit(formData, initialData?.id);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        setImage(file);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setError("Please select an image file.");
        e.target.value = null;
      }
    }
  };
  const validatePrice = (value) => {
    // Convert string to number, removing commas
    const numValue = Number(value.replace(/,/g, ''));

    // Check if it's a valid number
    if (isNaN(numValue)) {
      setError('Please enter a valid number');
      return false;
    }


    // For amounts up to 10,000 IQD, must be in increments of 500
    if (numValue <= 10000) {
      if (numValue % 500 !== 0) {
        setError('For amounts up to 10,000 IQD, price must be in increments of 500 (e.g., 3,000 or 3,500 IQD)');
        return false;
      }
    }
    // For larger amounts, must be in increments of 1000
    else if (numValue % 1000 !== 0) {
      setError('For amounts above 10,000 IQD, price must be in increments of 1,000');
      return false;
    }

    setError('');
    return true;
  };

  const formatPrice = (value) => {
    // Remove non-digit characters
    const digits = value.replace(/\D/g, '');
    // Format with commas
    return digits.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleChange = (e) => {
    const rawValue = e.target.value;
    const formattedValue = formatPrice(rawValue);
    setPrice(formattedValue);
    validatePrice(formattedValue);
  };


  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <h2 className="text-2xl font-bold mb-4">
        {initialData ? "Edit Menu Item" : "Add New Menu Item"}
      </h2>
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
          <span className="block sm:inline">{error}</span>
        </div>
      )}
      <div  className="space-y-4">
        <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
          Name
        </label>
        <input
          id="name"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Enter item name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      {/* add kurdish name */}
      <div  className="space-y-4">
        <label htmlFor="nameKu" className="block text-sm font-medium text-gray-700 mb-1">
          NameKu
        </label>
        <input
          id="nameKu"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Enter item name"
          value={nameKu}
          onChange={(e) => setNameKu(e.target.value)}
          required
        />
      </div>
      {/* add arabic name */}
      <div  className="space-y-4">
        <label htmlFor="nameAr" className="block text-sm font-medium text-gray-700 mb-1">
          NameAr
        </label>
        <input
          id="nameAr"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Enter item name"
          value={nameAr}
          onChange={(e) => setNameAr(e.target.value)}
          required
        />
      </div>

      <div className="relative">
        <label htmlFor="price" className="block text-sm font-medium text-gray-700 mb-1">
          Price
        </label>
        <input
          id="price"
          type="text"
          className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${
            error ? 'border-red-500' : 'border-gray-300'
          }`}
          placeholder="Enter price (e.g., 3,000)"
          value={price}
          onChange={handleChange}
          required
        />
         <div className="absolute right-3 top-9 text-gray-500 text-sm">IQD</div>
      </div>
      <div>
        <label htmlFor="image" className="block text-sm font-medium text-gray-700 mb-1">
          Image
        </label>
        <div className="mt-1 flex items-center">
          <label htmlFor="image-upload" className="cursor-pointer bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            <Upload className="h-5 w-5 inline-block mr-2" />
            Upload Image
          </label>
          <input
            id="image-upload"
            type="file"
            className="sr-only"
            accept="image/*"
            onChange={handleImageChange}
          />
        </div>
        {imagePreview && (
          <div className="mt-2">
            <img src={imagePreview} alt="Menu item preview" className="h-32 w-32 object-cover rounded-md" />
          </div>
        )}
      </div>
      <div>
        <label htmlFor="category" className="block text-sm font-medium text-gray-700 mb-1">
          Category
        </label>
        <select
          id="category"
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={categoryId}
          onChange={(e) => setCategoryId(e.target.value)}
          required
        >
          <option value="" disabled>Select a category</option>
          {categories.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>
        {/* Active Status */}
        <div className="flex items-center justify-between">
          <label htmlFor="isActive" className="block text-sm font-medium text-gray-700 mb-1">
            Active
          </label>
          <Toggle checked={isActive} onChange={() => setIsActive(!isActive)} />
      </div>
      </div>
      <button
        type="submit"
        className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
      >
        {initialData ? "Update" : "Add"} Menu Item
      </button>
    </form>
  );
};

export const MenuComponent = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingMenuItem, setEditingMenuItem] = useState(null);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [error, setError] = useState("");
  const [togglingItems, setTogglingItems] = useState(new Set()); // Track items being toggled

  useEffect(() => {
    fetchMenuItems();
  }, []);

  const fetchMenuItems = async () => {
    try {
      const response = await api.get(`/menus`);
      setMenuItems(response.data.data);
    } catch (error) {
      console.error(error);
      setError("Failed to fetch menu items. Please try again later.");
    }
  };
  const handleStatusToggle = async (item) => {
    setTogglingItems(prev => new Set(prev).add(item.id));
    try {
      const formData = new FormData();
      // Only send the necessary fields for status update
      formData.append('isActive', !item.isActive);
      formData.append('name', item.name);
      formData.append('nameKu', item.nameKu);
      formData.append('nameAr', item.nameAr);
      formData.append('price', item.price);
      await api.put(`/menus/${item.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      // Update local state after successful API call
      setMenuItems(prevItems =>
        prevItems.map(prevItem =>
          prevItem.id === item.id
            ? { ...prevItem, isActive: !prevItem.isActive }
            : prevItem
        )
      );
    } catch (err) {
      setError('Failed to update status. Please try again.');
      console.error('Error updating status:', err);
    } finally {
      setTogglingItems(prev => {
        const newSet = new Set(prev);
        newSet.delete(item.id);
        return newSet;
      });
    }
  };

  const handleSubmit = async (itemData, menuId) => {
    try {
      let response;
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
     
      if (menuId) {
        for (let [key, value] of itemData.entries()) {
          console.log(key, value);
        }
        response = await api.put(`/menus/${menuId}`, itemData, config);
        
      } else {
        response = await api.post(`/menus`, itemData, config);

      }

      if (response.data.success) {
        await fetchMenuItems();
        setIsModalOpen(false);
        setEditingMenuItem(null);
      } else {
        setError("Failed to update menu item");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setError(error.response?.data?.message || "An error occurred while updating the menu item");
    }
    console.log('Submitting form data:', );

  };

  const handleEdit = (item) => {
    setEditingMenuItem(item);
    setIsModalOpen(true);
  };

  const handleDelete = async () => {
    if (itemToDelete) {
      try {
        await api.delete(`/menus/${itemToDelete.id}`);
        await fetchMenuItems();
        setIsDeleteConfirmOpen(false);
        setItemToDelete(null);
      } catch (error) {
        console.error(error);
        setError("Failed to delete menu item. Please try again.");
      }
    }
  };

  
  const handleExcelUpload = async (event) => {
    try {
      const file = event.target.files[0];
      const workbook = await XLSX.read(await file.arrayBuffer(), { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const data = XLSX.utils.sheet_to_json(worksheet);
  
      // Fetch categories to create a mapping of names to IDs
      const categoryResponse = await api.get('/allcategories');
      const categories = categoryResponse.data.data.data;
  
      // Create a mapping of category names to IDs
      const categoryMap = {};
      categories.forEach(category => {
        categoryMap[category.name] = category.id; // Adjust property names as necessary
      });
      
  
      // Create menu items from the Excel data
      for (const item of data) {
        const formData = new FormData();
        formData.append('name', item.Name);
        formData.append('nameKu', item.NameKu);
        formData.append('nameAr', item.NameAr);
        formData.append('isActive', item.IsActive);
        formData.append('price', item.Price);
  
        // Find the categoryId using the category name
        const categoryId = categoryMap[item.CategoryName]; // Adjust the property name as necessary
        if (categoryId) {
          formData.append('categoryId', categoryId);
        } else {
          console.warn(`Category not found for: ${item.CategoryName}`);
        }
  
        // Handle image upload if present in the Excel data
        if (item.Image) {
          const imageData = await fetch(item.Image).then(res => res.blob());
          formData.append('image', imageData, 'image.jpg');
        }
          //validation to inputs 
          if (!item.Name.trim() || !item.Price || !categoryId || !item.NameAr.trim() || !item.NameKu.trim()) {
            setError("All fields are required");
            return;
          }
        
        await api.post('/menus', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }
  
      await fetchMenuItems();
      setError('');
      event.target.value = null; // Reset the file input
    } catch (err) {
      console.error('Error uploading Excel:', err);
      setError('Failed to upload and create menu items from Excel file.');
    }
  };
  



  return (
       <div className="bg-white shadow-lg rounded-lg p-6 max-w-6xl mx-auto my-10">

      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold text-gray-800">Menu Management</h2>

          <div className="flex space-x-4">
          <label htmlFor="excel-upload" className="cursor-pointer bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300 flex items-center">
            <Upload className="h-5 w-5 inline-block mr-2" />
            Upload Excel
          </label>
          <input
            id="excel-upload"
            type="file"
            className="sr-only"
            accept=".xlsx, .xls"
            onChange={handleExcelUpload}
          />
          <button
          onClick={() => setIsModalOpen(true)}
          className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300 flex items-center"
        >
          <Plus size={20} className="mr-2" /> Add Menu Item
        </button>
        </div>
        
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span className="block sm:inline">{error}</span>
        </div>
      )}

      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Name
              </th>
              {/* kurdish */}
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Kurdish Name
              </th>
              {/* arabic */}
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Arabic Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Price
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Category
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Image
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {menuItems.map((item) => (
              <tr key={item.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">{item.name}</td>
                <td className="px-6 py-4 whitespace-nowrap">{item.nameKu}</td>
                <td className="px-6 py-4 whitespace-nowrap">{item.nameAr}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center gap-2">
                    <Toggle
                      checked={item.isActive}
                      onChange={() => handleStatusToggle(item)}
                      disabled={togglingItems.has(item.id)}
                    />
                      {item.isActive ? (
                        <Eye className="h-4 w-4 text-green-500" />
                      ) : (
                        <EyeOff className="h-4 w-4 text-gray-500" />
                      )}
                    </div>
                  </td>
                <td className="px-6 py-4 whitespace-nowrap">{item.price}
                  <span className="mx-1">IQD</span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {item.category.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <img
                    src={`https://cc-cafe-rest.com//${item.image}`}
                    alt={item.name}
                    className="w-10 h-10 rounded-full object-cover"
                  />
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button
                    onClick={() => handleEdit(item)}
                    className="text-indigo-600 hover:text-indigo-900 mr-4"
                  >
                    <Edit2 size={20} />
                  </button>
                  <button
                    onClick={() => {
                      setItemToDelete(item);
                      setIsDeleteConfirmOpen(true);
                    }}
                    className="text-red-600 hover:text-red-900"
                  >
                    <Trash2 size={20} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setEditingMenuItem(null);
        }}
      >
        <MenuItemForm
          onSubmit={handleSubmit}
          initialData={editingMenuItem}
          onClose={() => {
            setIsModalOpen(false);
            setEditingMenuItem(null);
          }}
        />
      </Modal>

      <Modal
        isOpen={isDeleteConfirmOpen}
        onClose={() => setIsDeleteConfirmOpen(false)}
      >
        <div className="text-center">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            Confirm Deletion
          </h3>
          <p className="text-sm text-gray-500 mb-4">
            Are you sure you want to delete the menu item "{itemToDelete?.name}
            "? This action cannot be undone.
          </p>
          <div className="flex justify-center space-x-4">
            <button
              onClick={() => setIsDeleteConfirmOpen(false)}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition duration-300"
            >
              Cancel
            </button>
            <button
              onClick={handleDelete}
              className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition duration-300"
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MenuComponent;
