import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserComponent from '../components/Dashboard/userComponent';
import CategoryComponent from '../components/Dashboard/categoryComponent';
import MenuComponent from '../components/Dashboard/menuComponent';
import { AuthContext } from '../context/AuthProvider';

const Dashboard = () => {
  const { auth, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('users');

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };
console.log('====================================');
  console.log(auth);
  console.log('====================================');
  
  if (!auth || !auth.user) {
    return <div>Loading...</div>; // Or redirect to login page
  }
  
  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Restaurant Management Dashboard</h1>
        <div>
          <span className="mr-4">Welcome, {auth.user.user.username}!</span>
          <button
            onClick={handleLogout}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Logout
          </button>
        </div>
      </div>
      <div className="mb-4">
        <button
          className={`mr-2 px-4 py-2 rounded-md ${activeTab === 'users' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveTab('users')}
        >
          Users
        </button>
        <button
          className={`mr-2 px-4 py-2 rounded-md ${activeTab === 'categories' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveTab('categories')}
        >
          Categories
        </button>
        <button
          className={`px-4 py-2 rounded-md ${activeTab === 'menu' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveTab('menu')}
        >
          Menu
        </button>
      </div>
      {activeTab === 'users' && <UserComponent />}
      {activeTab === 'categories' && <CategoryComponent />}
      {activeTab === 'menu' && <MenuComponent />}
    </div>
  );
};

export default Dashboard;
