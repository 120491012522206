import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import EmptyMenu from '../components/emptyMenu';
import { ChevronLeft, Loader2 } from 'lucide-react';

function CategorySelector() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  
  // Get selected language from localStorage
  const selectedLanguage = localStorage.getItem('Language') || 'en';
  //if Language == 'ar', 'ku' or 'en' add only en localstorage

  // Directional styling based on language
  const isRTL = selectedLanguage === 'ar' || selectedLanguage === 'ku';
  
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://cc-cafe-rest.com/api/categories",
          // "http://localhost:8080/api/categories",
          {
            params: { lang: selectedLanguage },
            headers: {
              'Accept-Language': selectedLanguage
            }
          }
        );
        setCategories(response.data.data);
        setLoading(false);
      } catch (fetchError) {
        //navigate to error page 
        navigate('/');
        console.error(fetchError);
        setError(fetchError);
        setLoading(false);
      }
    };

    fetchCategories();
  }, [navigate, selectedLanguage]);

  const handleCategoryClick = (categoryId, categoryName) => {
    navigate(`/menu/${categoryId}`, { 
      state: { 
        categoryName,
        language: selectedLanguage 
      } 
    });
  };

  const getLocalizedText = () => {
    const texts = {
      home: {
        en: 'Home',
        ar: 'الرئيسية',
        ku: 'سەرەتا'
      },
      error: {
        en: 'Error fetching data:',
        ar: 'خطأ في جلب البيانات:',
        ku: 'هەڵە لە بەدەستهێنانی داتا:'
      }
    };

    return texts;
  };

  const localizedText = getLocalizedText();

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <Loader2 className="w-12 h-12 text-[#8B0000] animate-spin" />
      </div>
    );
  }

  if (error) {
    return (
      <div dir={isRTL ? 'rtl' : 'ltr'}>
        {localizedText.error[selectedLanguage]} {error.message}
      </div>
    );
  }

  return (
    <div 
      className="p-6 bg-[#8B0000] shadow-md rounded-md"
      dir={isRTL ? 'rtl' : 'ltr'}
    >
      <Link to='/' className={`flex items-center mb-4 text-white`}>
        <ChevronLeft className={`w-6 h-6 ${isRTL ? 'ml-2 transform rotate-180' : 'mr-2'}`} />
        <h2 className="text-xl font-bold text-white">
          {localizedText.home[selectedLanguage]}
        </h2>
      </Link>

      {categories.length === 0 ? (
        <EmptyMenu />
      ) : (
        <div className="min-h-screen p-4">
          <div className="max-w-4xl mx-auto">
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
              {categories.map((category, index) => (
                <div
                  onClick={() => handleCategoryClick(category.id, category.name || category.nameKu || category.nameAr )}
                  key={index}
                  className="bg-white rounded-lg p-4 cursor-pointer transition duration-300 ease-in-out transform hover:scale-105"
                >
                  <div className="grid grid-cols-1 gap-4">
                    <div className="relative overflow-hidden rounded-lg h-48">
                      <img
                        src={`https://cc-cafe-rest.com//${category.image}`}
                        alt={category.name}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2">
                        <h3 className="font-bold mb-2 text-lg">
                    {category.name || category.nameKu || category.nameAr}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CategorySelector;